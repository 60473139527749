import { baseApi } from "./api"

export type TeamList = {
  id: number
  name: string[]
}

export type Team = {
  id: number
  name: string
}

const teamsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    teams: build.query<TeamList, void>({
      query: () => ({
        url: "/teams",
        method: "GET",
      }),
      providesTags: ["teams"],
    }),
    team: build.query<Team, { id: number | null }>({
      query: ({ id }) => ({
        url: `/teams/${id}`,
        method: "GET",
      }),
      providesTags: (result, error) => [{ type: "teams", id: result?.id }],
    }),
  }),
  overrideExisting: true,
})

export default teamsApi
export const { useTeamsQuery, useTeamQuery } = teamsApi
