import { SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import {
  CreateCustomerSchema,
  createCustomerSchema,
  useCreateCustomerMutation,
} from "../../api/customers"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "react-i18next"
import { Form } from "../../ui/Form/Form/Form"
import TextInput from "../../ui/Form/TextInput/TextInput"
import PhoneInput from "../../ui/Form/PhoneInput/PhoneInput"
import NumberInput from "../../ui/Form/NumberInput/NumberInput"
import AutoComplete from "../../ui/Form/AutoComplete/AutoComplete"
import {
  useCreateBusinessIndustryMutation,
  useGetBusinessIndustriesQuery,
} from "../../api/businessIndustries"
import { Button } from "../../ui/Button/Button"
import { handleTypedFormErrors } from "../../utils/utils"
import { useNavigate } from "react-router-dom"
import { PlusIcon } from "lucide-react"

const NewCustomer = () => {
  const { t } = useTranslation("customers")
  const navigate = useNavigate()
  const formMethods = useForm<CreateCustomerSchema>({
    resolver: zodResolver(createCustomerSchema),
    defaultValues: {
      address: "",
      businessIndustry: [],
      city: "",
      contacts: [],
      email: "",
      employeeNumber: null,
      name: "",
      phone: [
        {
          phoneNumber: "",
        },
      ],
      revenue: null,
      website: "",
      zip: null,
    },
  })
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      name: "phone",
      control: formMethods.control,
    },
  )

  const { data: businessIndustries } = useGetBusinessIndustriesQuery()
  const [
    createBusinessIndustry,
    { isLoading: isCreateBusinessIndustryLoading },
  ] = useCreateBusinessIndustryMutation()
  const [createCustomer, { isLoading: isCreateCustomerLoading }] =
    useCreateCustomerMutation()

  const handleCreateNewBusinessIndustry = async (value: string) => {
    try {
      const result = await createBusinessIndustry({ value }).unwrap()
      formMethods.setValue("businessIndustry", [
        ...formMethods.getValues()?.businessIndustry,
        result.id,
      ])
    } catch (err) {
      handleTypedFormErrors(err)
    }
  }

  const handleSubmit: SubmitHandler<CreateCustomerSchema> = async (values) => {
    try {
      const result = await createCustomer(values).unwrap()
      if (result) navigate("..")
    } catch (err) {
      alert("Hiba tortent")
      handleTypedFormErrors(err)
    }
  }

  return (
    <div>
      <h1 className="text-2xl">{t("createTitle")}</h1>
      <div className="my-2 max-w-3xl mx-auto">
        <Form {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <TextInput name="name" label={t("name")} />
            <TextInput name="website" label={t("website")} />
            <div className="flex gap-8">
              <NumberInput
                allowLeadingZeros={false}
                allowNegative={false}
                decimalScale={0}
                name="zip"
                label={t("zip")}
              />
              <TextInput name="city" label={t("city")} className="flex-1" />
            </div>
            <TextInput name="address" label={t("address")} className="flex-1" />
            <div className="flex gap-8">
              <NumberInput
                allowLeadingZeros={false}
                allowNegative={false}
                decimalScale={0}
                name="employeeNumber"
                label={t("employeeNumber")}
                className="flex-1"
              />
              <NumberInput
                allowLeadingZeros={false}
                allowNegative={false}
                decimalScale={0}
                name="revenue"
                label={t("revenue")}
                className="flex-1"
                decimalSeparator=","
                thousandSeparator="."
              />
              <AutoComplete
                name="businessIndustry"
                label={t("businessIndustry")}
                options={
                  businessIndustries?.map((bi) => ({
                    label: bi.value,
                    value: bi.id,
                  })) || []
                }
                isMulti
                className="flex-1"
                onCreateOption={handleCreateNewBusinessIndustry}
                creatable
                isLoading={isCreateBusinessIndustryLoading}
              />
            </div>
            <TextInput name="email" label={t("email")} />
            <div className="flex flex-wrap gap-x-8">
              {fields.map((field, index) => (
                <PhoneInput
                  key={field.id}
                  name={`phone.${index}.phoneNumber`}
                  label={t("phone")}
                />
              ))}
              <Button
                type="button"
                variant="outline"
                onClick={() => append({ phoneNumber: "" })}
                className="mt-7"
              >
                <PlusIcon className="w-4 h-4" />
                {t("addNewPhone")}
              </Button>
            </div>
            <Button
              className="mt-7"
              type="submit"
              isLoading={isCreateCustomerLoading}
            >
              {t("createCustomerSubmit")}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  )
}

export default NewCustomer
