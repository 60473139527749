import { SubmitHandler, useForm } from "react-hook-form"

import { Button } from "../../ui/Button/Button"
import {
  RegisterSchema,
  registerSchema,
  useRegisterMutation,
} from "../../api/auth"
import { Form } from "../../ui/Form/Form/Form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import TextInput from "../../ui/Form/TextInput/TextInput"

const Register = () => {
  const { t } = useTranslation("register")
  const [register] = useRegisterMutation()
  const navigate = useNavigate()

  const formMethods = useForm<RegisterSchema>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      confirmPassword: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      team: "",
    },
  })

  const onSubmit: SubmitHandler<RegisterSchema> = (data) => {
    register(data)
  }

  return (
    <div className="h-full flex flex-col text-foreground">
      <h1 className="text-2xl">{t("title")}</h1>
      <div className="w-96">
        <Form {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className="flex gap-4">
              <TextInput name="firstName" label={t("firstName")} />
              <TextInput name="lastName" label={t("lastName")} />
            </div>
            <TextInput name="team" label={t("team")} />
            <TextInput name="email" label={t("email")} type="email" />
            <TextInput name="password" label={t("password")} type="password" />
            <TextInput
              name="confirmPassword"
              label={t("confirmPassword")}
              type="password"
            />
            <div className="flex flex-col gap-4">
              <Button type="submit" className="mt-5">
                {t("submit")}
              </Button>
              <Button
                type="button"
                variant="ghost"
                onClick={() => navigate("/auth/login")}
              >
                {t("to_login")}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}

export default Register
