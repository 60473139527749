import { Link, useMatch, useResolvedPath } from "react-router-dom"
import { ICON_MAP, IconKeys } from "../../../constants/icons"
import { useTranslation } from "react-i18next"
import { menuItems } from "../../../constants/menu"

const Menu = () => {
  const createMenu = (items: IMenu) =>
    items.map((m) => {
      return (
        <MenuItem
          icon={m.icon}
          label={m.label}
          path={m.path}
          key={m.path}
          matchPattern={m.matchPattern}
        />
      )
    })

  return <>{createMenu(menuItems)}</>
}

export interface IMenuItem {
  label: string
  path: string
  icon?: IconKeys
  matchPattern?: string
  closeOnClick?: boolean
}

export type IMenu = IMenuItem[]

const MenuItem = ({ icon, label, path, matchPattern }: IMenuItem) => {
  const resolved = useResolvedPath(path)
  const match = useMatch({
    path: resolved.pathname + (matchPattern ?? ""),
  })
  const { t } = useTranslation("sidebar")

  const IconComponent = icon ? ICON_MAP[icon] : undefined

  return (
    <li>
      <Link to={path} className="no-underline text-inherit">
        <div
          className={`flex flex-row items-center h-11 focus:outline-none border-l-4 ${
            !match ? "border-transparent" : ""
          } hover:border-primary`}
          title={label}
        >
          {IconComponent ? (
            <span className="inline-flex justify-center items-center ml-4">
              <IconComponent className="w-4 h-4" />
            </span>
          ) : (
            <div className="pl-4" />
          )}
          <span className="ml-2 text-sm tracking-wide truncate">
            {t(label)}
          </span>
        </div>
      </Link>
    </li>
  )
}

export default Menu
