import { LogOut } from "lucide-react"
import { useTranslation } from "react-i18next"
import Menu from "./components/Menu"
import { Button } from "../Button/Button"
import { useLogoutMutation } from "../../api/auth"

const Sidebar = () => {
  const { t } = useTranslation("sidebar")
  const [logout] = useLogoutMutation()

  return (
    <div className="fixed flex flex-col top-0 left-0 w-64 bg-card text-card-foreground h-full border-r p-4">
      <div className="flex items-center justify-center h-14 border-b">
        <div>LOGO</div>
      </div>
      <div className="overflow-y-auto overflow-x-hidden flex-grow">
        <ul className="flex flex-col py-4 space-y-1">
          <li className="px-5">
            <div className="flex flex-row items-center h-8">
              <div className="text-sm font-light tracking-wide ">Menu</div>
            </div>
          </li>
          <Menu />
        </ul>
      </div>
      <div className="mt-auto">
        <ul className="flex flex-col py-4 space-y-1 border-t">
          <li className="px-5 text-center">
            <Button variant="secondary" onClick={() => logout()}>
              <LogOut className="h-4 w-4 mr-4" />
              {t("logout")}
            </Button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
