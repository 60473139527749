import { Loader2 } from "lucide-react"

const Loader = () => {
  return (
    <div className="pl-64 pt-12 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
      <Loader2 className="w-20 h-20 animate-spin" />
    </div>
  )
}

export default Loader
