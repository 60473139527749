import "./App.css"
import { useAppDispatch } from "./store/hooks"
import { useUserQuery } from "./api/user"
import { useEffect } from "react"
import { setUser } from "./store/userSlice/userSlice"
import AppRouter from "./routes/AppRouter"
import {
  LOCAL_STORAGE_THEME_KEY,
  setMode,
} from "./store/configSlice/configSlice"

function App() {
  const { data, isLoading } = useUserQuery()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const savedMode = localStorage.getItem(LOCAL_STORAGE_THEME_KEY)

    const listener = (e: MediaQueryListEvent) => {
      if (!savedMode) {
        dispatch(setMode(e.matches ? "dark" : "light"))
      }
    }

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", listener)

    if (savedMode) {
      dispatch(setMode(savedMode as "light" | "dark"))
    }

    if (data) {
      dispatch(setUser(data))
    }

    return () =>
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", listener)
  }, [data, dispatch])

  return (
    <div className="flex h-screen overflow-hidden text-foreground">
      {isLoading ? null : <AppRouter />}
    </div>
  )
}

export default App
