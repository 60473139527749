import { createSlice } from "@reduxjs/toolkit"

export const LOCAL_STORAGE_THEME_KEY = "__X__CRM__THEME"

interface ConfigSliceState {
  mode: "dark" | "light"
}

const initialState: ConfigSliceState = {
  mode: "light",
}

export const configSlice = createSlice({
  name: "configSlice",
  initialState,
  reducers: {
    setMode: (state, action: { payload: ConfigSliceState["mode"] }) => {
      localStorage.setItem(LOCAL_STORAGE_THEME_KEY, action.payload)
      if (action.payload === "dark") {
        document.body.classList.add("dark")
      } else {
        document.body.classList.remove("dark")
      }
      return { ...state, mode: action.payload }
    },
  },
})

export const { setMode } = configSlice.actions
