import { Moon, Sun } from "lucide-react"
import { useTeamQuery } from "../../api/teams"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { Button } from "../Button/Button"
import Breadcrumbs from "./Breadcrumbs"
import { setMode } from "../../store/configSlice/configSlice"

const Header = () => {
  const mode = useAppSelector((state) => state.configSlice.mode)
  const selectedTeam = useAppSelector((state) => state.teamsSlice.selectedTeam)
  const dispatch = useAppDispatch()
  const { data: team } = useTeamQuery(
    { id: selectedTeam },
    { skip: selectedTeam === null },
  )

  const handleChangeMode = () => {
    dispatch(setMode(mode === "dark" ? "light" : "dark"))
  }

  return (
    <div className="w-full bg-card flex justify-between gap-2 items-center p-4 shadow-md">
      <Breadcrumbs />
      <div className="flex items-center">
        {team?.name}
        <Button onClick={() => handleChangeMode()} variant="ghost" size="icon">
          {mode === "dark" ? <Sun /> : <Moon />}
        </Button>
      </div>
    </div>
  )
}

export default Header
