import { z } from "zod"
import { ERROR_CODES } from "../constants/textCodes"
import { baseApi } from "./api"

export type BusinessIndustry = {
  id: number
  value: string
}

const businessIndustryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBusinessIndustries: build.query<BusinessIndustry[], void>({
      query: () => ({
        url: "/business-industries",
        method: "GET",
      }),
      providesTags: ["businessIndustry"],
    }),
    createBusinessIndustry: build.mutation<
      BusinessIndustry,
      CreateBusinessIndustrySchema
    >({
      query: (body) => ({
        url: "/business-industries",
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["businessIndustry"],
    }),
  }),
  overrideExisting: true,
})

export const createBusinessIndustrySchema = z.object({
  value: z.string().min(1, ERROR_CODES.required),
})

export type CreateBusinessIndustrySchema = z.infer<
  typeof createBusinessIndustrySchema
>

export default businessIndustryApi
export const {
  useCreateBusinessIndustryMutation,
  useGetBusinessIndustriesQuery,
} = businessIndustryApi
