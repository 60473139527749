import { ChevronLeft } from "lucide-react"
import { useMatches } from "react-router-dom"

const Breadcrumbs = () => {
  let matches = useMatches()
  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean((match.handle as any)?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => (match.handle as any).crumb(match.data))

  return (
    <div>
      {crumbs.map((crumb, index) => (
        <div
          key={index}
          className="flex text-gray-400 font-light text-sm items-center"
        >
          <ChevronLeft />
          <span className="hover:underline">{crumb}</span>
        </div>
      ))}
    </div>
  )
}

export default Breadcrumbs
