import { AxiosError, AxiosRequestConfig } from "axios"
import type { BaseQueryFn } from "@reduxjs/toolkit/query"
import { Mutex } from "async-mutex"

import { ApiClient } from "./apiClient"

type ErrorResponse = {
  message: string
  statusCode: number
}

const apiClient = new ApiClient()
const mutex = new Mutex()

const baseQuery = (
  {
    baseURL,
  }: {
    baseURL?: string
  } = { baseURL: undefined },
): BaseQueryFn<AxiosRequestConfig, unknown, ErrorResponse> => {
  return async (config) => {
    const url = baseURL || import.meta.env.VITE_API_URL
    try {
      await mutex.waitForUnlock()
      let result = await apiClient.request({
        baseURL: url,
        ...config,
      })
      return { data: result.data }
    } catch (axiosError) {
      let err = axiosError as AxiosError<ErrorResponse>
      if (err.response?.status === 401) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire()
          try {
            const refreshResult = await apiClient.request({
              baseURL: url,
              ...config,
              method: "POST",
              url: "/auth/refresh",
            })
            if (refreshResult.status === 200) {
              // retry the initial query
              return await apiClient.request({
                baseURL: url,
                ...config,
              })
            }
          } finally {
            // release must be called once the mutex should be released again.
            release()
          }
        }
      }
      return {
        error: err.response?.data,
      }
    }
  }
}

export { baseQuery }
