import {
  Route,
  Link,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from "react-router-dom"
import AuthenticatedLayout from "../layout/AuthenticatedLayout"
import UnauthenticatedLayout from "../layout/UnauthenticatedLayout"
import Dashboard from "../pages/Dashboard/Dashboard"
import Login from "../pages/Login/Login"
import Register from "../pages/Register/Register"
import Customers from "../pages/Customers/Customers"
import i18next from "i18next"
import NewCustomer from "../pages/Customers/NewCustomer"
import CustomerDetails from "../pages/Customers/CustomerDetails"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      <Route path="/auth" element={<UnauthenticatedLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
      </Route>
      <Route path="/" element={<AuthenticatedLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="customers">
          <Route index element={<Customers />} />
          <Route
            path="new"
            element={<NewCustomer />}
            handle={{
              crumb: () => (
                <Link to="./customers">{i18next.t("sidebar:customers")}</Link>
              ),
            }}
          />
          <Route
            path=":id"
            element={<CustomerDetails />}
            handle={{
              crumb: () => (
                <Link to="./customers">{i18next.t("sidebar:customers")}</Link>
              ),
            }}
          />
        </Route>
      </Route>
    </Route>,
  ),
)

const AppRouter = () => {
  return <RouterProvider router={router} />
}
export default AppRouter
