import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

import { baseApi } from "../api/api"
import { userSlice } from "./userSlice/userSlice"
import { teamsSlice } from "./teamsSlice/teamsSlice"
import { configSlice } from "./configSlice/configSlice"

const middlewares = [baseApi.middleware]

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [baseApi.reducerPath]: baseApi.reducer,
    [userSlice.name]: userSlice.reducer,
    [teamsSlice.name]: teamsSlice.reducer,
    [configSlice.name]: configSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
})

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
