import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useAppSelector } from "../store/hooks"
import Sidebar from "../ui/Sidebar/Sidebar"
import Header from "../ui/Header/Header"

const AuthenticatedLayout = () => {
  const user = useAppSelector((state) => state.userSlice.user)
  const location = useLocation()

  if (!user) {
    return (
      <Navigate to="/auth/login" state={{ from: location.pathname }} replace />
    )
  }

  return (
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-secondary text-secondary-foreground">
      <Sidebar />
      <nav className="pl-64 fixed w-full h-12 z-10">
        <Header />
      </nav>
      <main className="ml-64 p-8 mt-12 flex-1">
        <Outlet />
      </main>
    </div>
  )
}

export default AuthenticatedLayout
