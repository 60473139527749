import { Controller, useFormContext } from "react-hook-form"
import ReactSelect, { ActionMeta, components } from "react-select"
import CreatableSelect from "react-select/creatable"
import { useMemo } from "react"
import BaseControl, { ControlProps } from "../BaseControl/BaseControl"

export interface SelectOptions<T> {
  label: string
  value: T
  disabled?: boolean
}

export type OptionChangeAction<T> = ActionMeta<SelectOptions<T>>

interface ISelectProps<T> extends ControlProps {
  options: SelectOptions<T>[]
  isMulti?: boolean
  creatable?: boolean
  isClearable?: boolean
  onCreateOption?: (newOption: string) => void
  onChangeOptions?: (action: OptionChangeAction<T>) => void
  isLoading?: boolean
}

const Option = (props: any) => (
  <components.Option
    {...props}
    className={`cursor-pointer ${
      props.isSelected ? "bg-lightBlue-100 text-white" : "text-darkBlue-100"
    }`}
  />
)

const Input = (props: any) => (
  <components.Input {...props} className="focus:border-darkBlue-100" />
)

const AutoComplete = <T,>(props: ISelectProps<T>) => {
  const {
    name,
    label,
    options,
    placeholder,
    disabled,
    isMulti,
    creatable,
    className,
    isClearable = true,
    onChangeOptions,
    onCreateOption,
    isLoading,
  } = props
  const { control } = useFormContext()

  const SelectComponent = useMemo(
    () => (creatable ? CreatableSelect : ReactSelect),
    [creatable],
  )

  return (
    <BaseControl name={name} label={label} className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { onChange, value, ...restRenderProps } = field
          const selectValue = isMulti
            ? options.filter((o) => value?.includes(o.value))
            : options.find((o) => o.value === value) ?? null

          return (
            <SelectComponent
              {...restRenderProps}
              isLoading={isLoading}
              value={selectValue}
              onCreateOption={onCreateOption}
              placeholder={placeholder || null}
              isDisabled={disabled}
              options={options}
              components={{ Option, Input }}
              onChange={(changeValue, action) => {
                if (onChangeOptions) onChangeOptions(action)
                let newValue: any
                if (Array.isArray(changeValue)) {
                  newValue = changeValue.map((o) => o.value)
                } else {
                  newValue = (changeValue as SelectOptions<T>)?.value || null
                }
                onChange(newValue)
              }}
              isMulti={isMulti}
              isClearable={isClearable}
              formatCreateLabel={(inputText) => `Létrehozás: ${inputText}`}
              menuPosition="fixed"
              isOptionDisabled={(option) => !!option.disabled}
              styles={{
                container: (base) => ({
                  ...base,
                  borderRadius: "var(--radius)",
                  border: "1px solid hsl(var(--border))",
                }),
                control: (base) => ({
                  ...base,
                  minHeight: "46px",
                  backgroundColor: "hsl(var(--input))",
                  border: "none",
                  ":focus-within": {
                    border: "none",
                    outline: "none",
                    "--tw-ring-offset-width": "0.5px",
                    "--tw-ring-color": "hsl(var(--ring))",
                    "--tw-ring-offset-shadow":
                      "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
                    "--tw-ring-shadow":
                      "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
                    boxShadow:
                      "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
                  },
                }),
                singleValue: (base) => ({
                  ...base,
                  color: "inherit",
                }),
                input: (base) => ({
                  ...base,
                  color: "inherit",
                }),
                menu: (base) => ({
                  ...base,
                  left: 0,
                  borderRadius: 0,
                  backgroundColor: "hsl(var(--input))",
                  color: "inherit",
                }),
                option: (base, state) => ({
                  ...base,
                  padding: "8px 12px",
                  backgroundColor: state.isFocused
                    ? "hsl(var(--secondary))"
                    : "hsl(var(--input))",
                  color: state.isFocused
                    ? "hsl(var(--secondary-foreground))"
                    : "inherit",
                  ":hover": {
                    backgroundColor: "hsl(var(--secondary))",
                    color: "hsl(var(--secondary-foreground))",
                  },
                }),
                multiValue: (base) => ({
                  ...base,
                  background: "hsl(var(--card))",
                  color: "inherit",
                  borderRadius: "4px",
                  padding: "0.1rem",
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: "inherit",
                }),
                valueContainer: (base) => ({
                  ...base,
                  padding: "0 8px",
                }),
              }}
              menuShouldBlockScroll={true}
            />
          )
        }}
      />
    </BaseControl>
  )
}

export default AutoComplete
