import { SubmitHandler, useForm } from "react-hook-form"
import { Button } from "../../ui/Button/Button"
import { LoginSchema, loginSchema, useLoginMutation } from "../../api/auth"
import { Form } from "../../ui/Form/Form/Form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { LogInIcon } from "lucide-react"
import TextInput from "../../ui/Form/TextInput/TextInput"

const Login = () => {
  const { t } = useTranslation("login")
  const [login] = useLoginMutation()
  const navigate = useNavigate()

  const formMethods = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const onSubmit: SubmitHandler<LoginSchema> = (data) => {
    login(data)
  }

  return (
    <div className="h-full flex flex-col items-center justify-center text-foreground">
      <h1 className="text-2xl">{t("title")}</h1>
      <div className="w-96">
        <Form {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}
            className="space-y-8"
          >
            <TextInput name="email" label={t("email")} />
            <TextInput name="password" type="password" label={t("password")} />
            <div className="flex flex-col gap-4">
              <Button type="submit" className="mt-5">
                {t("submit")}
                <LogInIcon className="w-4 h-4 ml-2" />
              </Button>
              <Button
                type="button"
                variant="ghost"
                onClick={() => navigate("/auth/register")}
              >
                {t("to_register")}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}

export default Login
