import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Function to look up with a string path the value of an object key N levels down.
 * @param obj any object
 * @param path string path with . separator, eg: 'billing.zip'
 * @returns the value if found the path in {@link obj}, undefined otherwise
 */
export const getDeepValue = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  path: string,
): T[K] =>
  path
    .split(".")
    .reduce<any>(
      (acc, val) =>
        typeof acc === "object" &&
        acc !== null &&
        Object.hasOwnProperty.call(acc, val)
          ? acc[val]
          : undefined,
      obj,
    )

// TODO - implement it
export const handleTypedFormErrors = (...args: any[]) => {
  console.error(args)
  throw new Error("METHOD NOT IMPLEMENTED")
}
