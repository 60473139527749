import { z } from "zod"
import { ERROR_CODES } from "../constants/textCodes"
import { baseApi } from "./api"

export type CustomerListItem = {
  id: number
  name: string
  zip: number | null
  city: string | null
  address: string | null
  website: string | null
  email: string | null
  phone: string[]
  employeeNumber: number | null
  businessIndustry: BusinessIndustry[]
  revenue: number | null
}
export type CustomerList = CustomerListItem[]

export type Customer = {
  id: number
  name: string
  zip: number
  city: string
  address: string
  website: string
  email: string
  phone: string[]
  employeeNumber: number
  revenue: number
  contacts: any[] // TODO
  businessIndustry: BusinessIndustry[]
}

export interface BusinessIndustry {
  id: number
  value: string
}

const customersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createCustomer: build.mutation<Customer, CreateCustomerSchema>({
      query: (body) => ({
        url: "/customers",
        method: "POST",
        data: {
          ...body,
          phone: body.phone?.map((p) => p.phoneNumber),
        },
      }),
      invalidatesTags: ["customers"],
    }),
    getCustomers: build.query<CustomerList, void>({
      query: () => ({
        url: "/customers",
        method: "GET",
      }),
      providesTags: ["customers"],
    }),
    getCustomer: build.query<Customer, { id: string | undefined }>({
      query: ({ id }) => ({
        url: `/customers/${id}`,
        method: "GET",
      }),
      providesTags: (result, error) => [{ type: "customers", id: result?.id }],
    }),
    updateCustomer: build.mutation<Customer, UpdateCustomerSchema>({
      query: (body) => ({
        url: `/customers/${body.id}`,
        method: "PATCH",
        data: body,
      }),
      invalidatesTags: ["customers"],
    }),
  }),
  overrideExisting: true,
})

export default customersApi
export const {
  useGetCustomerQuery,
  useGetCustomersQuery,
  useCreateCustomerMutation,
} = customersApi

export const createContactSchema = z.object({
  name: z.string(),
  role: z.string().nullable(),
  phone: z.string().nullable(),
  email: z.string().email().nullable(),
})

export const createCustomerSchema = z.object({
  name: z.string().min(1, ERROR_CODES.required),
  zip: z.number().nullable().optional(),
  city: z.string().nullable().optional(),
  address: z.string().nullable().optional(),
  website: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  phone: z
    .array(z.object({ phoneNumber: z.string().nullable() }))
    .nullable()
    .optional(),
  employeeNumber: z.number().nullable().optional(),
  businessIndustry: z.array(z.number().nullable()),
  revenue: z.number().nullable().optional(),
  contacts: z.array(createContactSchema).nullable().optional(),
})

export const updateCustomerSchema = z
  .object({
    id: z.number(),
  })
  .and(createCustomerSchema.optional())

export type CreateContactSchema = z.infer<typeof createContactSchema>
export type CreateCustomerSchema = z.infer<typeof createCustomerSchema>
export type UpdateCustomerSchema = z.infer<typeof updateCustomerSchema>
