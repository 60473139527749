import React from "react"
import { useFormContext } from "react-hook-form"
import { cva } from "class-variance-authority"
import { cn, getDeepValue } from "../../../utils/utils"
import { useTranslation } from "react-i18next"
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../Form/Form"

const controlVariants = cva(
  "min-h-[46px] w-full border text-md py-1 px-2 placeholder:text-neutral-400 outline-0 rounded-lg w-full text-left ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 bg-input",
  {
    variants: {
      variant: {
        default: "",
        error: "text-red-600 border-red-600 focus:border-red-600",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
)

export interface BaseControlProps {
  children: React.ReactElement
  label?: React.ReactNode
  name: string
  className?: string
  description?: string
}

export interface ControlProps {
  name: string
  label?: React.ReactNode
  className?: string
  disabled?: boolean
  placeholder?: string
}

const BaseControl = (props: BaseControlProps) => {
  const { label, children, name, className, description } = props
  const { control } = useFormContext()
  const {
    formState: { errors },
  } = useFormContext()
  const { t } = useTranslation()
  const error = t(getDeepValue(errors, name)?.message as string)

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn("flex flex-col mx-0", className)}>
          {label ? (
            <FormLabel className="text-xs flex items-center text-foreground mb-1 mt-2">
              {label}
            </FormLabel>
          ) : null}
          <FormControl className="w-full relative">
            {React.cloneElement(children, {
              className: cn(
                controlVariants({ variant: error ? "error" : "default" }),
              ),
              ...field,
            })}
          </FormControl>
          {description ? (
            <FormDescription>{description}</FormDescription>
          ) : null}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export default BaseControl
