import { Home, Users } from "lucide-react"

export type IconProp = {
  className?: string
}

export const ICON_MAP = {
  home: Home,
  customers: Users,
} as const
export type IconKeys = keyof typeof ICON_MAP
