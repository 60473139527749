import { IMenu } from "../ui/Sidebar/components/Menu"

export const menuItems: IMenu = [
  {
    path: "/",
    icon: "home",
    label: "home",
  },
  {
    path: "/customers",
    icon: "customers",
    label: "customers",
    matchPattern: "/*",
  },
  // {
  //   type: "parentMenuItem",
  //   path: "/export",
  //   icon: "export",
  //   label: "Export",
  //   children: [
  //     {
  //       type: "menuItem",
  //       path: "/export/cikk",
  //       icon: "export",
  //       label: "Cikkek",
  //     },
  //     {
  //       type: "menuItem",
  //       path: "/export/partner",
  //       icon: "export",
  //       label: "Partnerek",
  //     },
  //   ],
  // },
]
