import { createSlice } from "@reduxjs/toolkit"
import { userSlice } from "../userSlice/userSlice"
import authApi from "../../api/auth"

interface TeamsSliceState {
  selectedTeam: number | null
}

const initialState: TeamsSliceState = {
  selectedTeam: null,
}

export const teamsSlice = createSlice({
  name: "teamsSlice",
  initialState,
  reducers: {
    setSelectedTeam: (state, action: { payload: number }) => {
      return { ...state, selectedTeam: action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userSlice.actions.setUser, (state, { payload }) => {
      if (payload) {
        state.selectedTeam = payload.defaultTeam
      }
    })
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          state.selectedTeam = payload.defaultTeam
        }
      },
    )
    builder.addMatcher(
      authApi.endpoints.register.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          state.selectedTeam = payload.defaultTeam
        }
      },
    )
  },
})

export const { setSelectedTeam } = teamsSlice.actions
