import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import HttpApi from "i18next-http-backend"

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    ns: ["error", "login", "register", "sidebar", "dashboard"],
    backend: {
      loadPath: "/translations/{{lng}}/{{ns}}.json",
    },
    lng: "hu",
    supportedLngs: ["hu", "en"],
    react: {
      useSuspense: false,
    },
  })
