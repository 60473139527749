import { useTranslation } from "react-i18next"
import { Button } from "../../ui/Button/Button"
import { UserPlus } from "lucide-react"
import { Link, useNavigate } from "react-router-dom"
import { CustomerListItem, useGetCustomersQuery } from "../../api/customers"
import { createColumnHelper } from "@tanstack/react-table"
import { DataTable } from "../../ui/Table/DataTable"
import TableActions from "../../ui/Table/components/TableActions"

const Customers = () => {
  const { t } = useTranslation("customers")
  const navigate = useNavigate()
  const { data: customers } = useGetCustomersQuery()

  console.log({ customers })

  const columnHelper = createColumnHelper<CustomerListItem>()

  const columns = [
    columnHelper.accessor((row) => `${row.id}`, {
      id: "id",
      header: t("id"),
    }),
    columnHelper.accessor("name", {
      header: t("name"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("city", {
      header: t("city"),
      cell: (info) => info.getValue() || "-",
    }),
    columnHelper.accessor((row) => `${row.zip || ""}`, {
      header: t("zip"),
      cell: (info) => info.getValue() || "-",
    }),
    columnHelper.accessor("address", {
      header: t("address"),
      cell: (info) => info.getValue() || "-",
    }),
    columnHelper.accessor(
      (row) => row.phone.map((phone) => phone).join(",") || "-",
      {
        header: t("phone"),
        cell: (info) =>
          info
            .getValue()
            .split(",")
            .map((v) => (
              <>
                <span>
                  {v}
                  <br />
                </span>
              </>
            )),
      },
    ),
    columnHelper.accessor("website", {
      header: t("website"),
      cell: (info) => {
        const value = info.getValue()

        if (value) {
          return (
            <Button
              variant="link"
              onClick={(e) => {
                e.stopPropagation()
                window.open(
                  value.includes("http") ? value : `http://${value}`,
                  "_blank",
                )
              }}
              className="text-left p-0"
            >
              {value}
            </Button>
          )
        } else {
          return "-"
        }
      },
    }),
    columnHelper.accessor(
      (row) => row.businessIndustry.map((bi) => bi.value).join(",") || "-",
      {
        header: t("businessIndustry"),
        cell: (info) =>
          info
            .getValue()
            .split(",")
            .map((v) => (
              <>
                <span>
                  {v}
                  <br />
                </span>
              </>
            )),
      },
    ),
    columnHelper.display({
      id: "actions",
      cell: (props) => (
        <TableActions
          menuItems={[
            {
              label: t("showDetails"),
              action: () => navigate(`./${props.row.original.id}`),
            },
          ]}
        />
      ),
    }),
  ]

  return (
    <div className="w-full h-full">
      <h1 className="text-2xl">{t("title")}</h1>
      <div className="my-2 text-right">
        <Link to="./new">
          <Button variant="outline">
            <UserPlus className="h-4 w-4 mr-2" /> {t("addNewButton")}
          </Button>
        </Link>
      </div>
      <div className="my-2 w-full h-full overflow-auto">
        <DataTable data={customers || []} columns={columns} />
      </div>
    </div>
  )
}

export default Customers
