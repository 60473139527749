import { createSlice } from "@reduxjs/toolkit"
import authApi, { User } from "../../api/auth"

interface UserSliceState {
  user?: User
}

const initialState: UserSliceState = {
  user: undefined,
}

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUser: (state, action: { payload: User }) => {
      return { ...state, user: action.payload }
    },
    reset: (_) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          state.user = payload
        }
      },
    )

    builder.addMatcher(
      authApi.endpoints.register.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          state.user = payload
        }
      },
    )

    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      state.user = undefined
    })
  },
})

export const { setUser, reset } = userSlice.actions
