import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../store/hooks"

const Dashboard = () => {
  const user = useAppSelector((state) => state.userSlice.user)
  const { t } = useTranslation("dashboard")

  return (
    <div>
      <h1 className="text-2xl">
        {t("greetings")}, {user?.firstName}.
      </h1>
    </div>
  )
}

export default Dashboard
