import { Controller, useFormContext } from "react-hook-form"
import BaseControl, { ControlProps } from "../BaseControl/BaseControl"
import { NumericFormat, NumericFormatProps } from "react-number-format"

interface IProps extends ControlProps, Omit<NumericFormatProps, "name"> {}

const NumberInput = ({ name, label, className, ...rest }: IProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onChange, ...restFieldProps } }) => (
        <BaseControl name={name} label={label} className={className}>
          <NumericFormat
            {...rest}
            {...restFieldProps}
            valueIsNumericString
            onValueChange={(e) => {
              onChange(e.floatValue)
            }}
          />
        </BaseControl>
      )}
    />
  )
}

export default NumberInput
