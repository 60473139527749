import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useAppSelector } from "../store/hooks"

const UnauthenticatedLayout = () => {
  const user = useAppSelector((state) => state.userSlice.user)
  const location = useLocation()

  if (user) {
    return <Navigate to={location.state?.from || "/"} replace />
  }

  return (
    <div className="h-full m-auto overflow-auto">
      <Outlet />
    </div>
  )
}

export default UnauthenticatedLayout
