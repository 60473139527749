import { Link, useParams } from "react-router-dom"
import { useGetCustomerQuery } from "../../api/customers"
import { useTranslation } from "react-i18next"
import { Button } from "../../ui/Button/Button"
import {
  Banknote,
  ChevronLeft,
  Factory,
  Mail,
  Phone,
  Store,
  Users,
} from "lucide-react"
import Loader from "../../ui/Loader/Loader"

const CustomerDetails = () => {
  const { t } = useTranslation("customers")
  const { id } = useParams()
  const { data: customer, isError, isLoading } = useGetCustomerQuery({ id })

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return (
      <div className="h-full w-full flex flex-col gap-4 items-center justify-center">
        <p>{t("customerNotFound")}</p>
        <Link to="..">
          <Button variant="outline">
            <ChevronLeft className="h-4 w-4" />
            {t("goBack")}
          </Button>
        </Link>
      </div>
    )
  }

  return (
    <div>
      <h1 className="text-2xl">{customer?.name}</h1>
      <div className="flex gap-4">
        <ul className="bg-card rounded-lg text-card-foreground my-4 p-4">
          {customer?.address ? (
            <li className="flex gap-4 items-center">
              <Store className="w-4 h-4" />
              {customer.zip} {customer.city} {customer.address}
            </li>
          ) : null}
          {customer?.phone?.map((phone, i) => (
            <li className="flex gap-4 items-center">
              {i === 0 ? <Phone className="w-4 h-4" /> : null}
              {i !== 0 ? <div className="w-4 h-4" /> : null}
              {phone}
            </li>
          ))}
          {customer?.email ? (
            <li className="flex gap-4 items-center">
              <Mail className="w-4 h-4" /> {customer.email}
            </li>
          ) : null}
          {customer?.businessIndustry?.map((bi, i) => (
            <li className="flex gap-4 items-center">
              {i === 0 ? <Factory className="w-4 h-4" /> : null}
              {i !== 0 ? <div className="w-4 h-4" /> : null}
              {bi.value}
            </li>
          ))}

          {customer?.revenue ? (
            <li className="flex gap-4 items-center">
              <Banknote className="w-4 h-4" />{" "}
              {new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF",
                maximumFractionDigits: 0,
              }).format(customer.revenue)}
            </li>
          ) : null}
          {customer?.employeeNumber ? (
            <li className="flex gap-4 items-center">
              <Users className="w-4 h-4" /> {customer.employeeNumber}
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  )
}

export default CustomerDetails
