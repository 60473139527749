import BaseControl, { ControlProps } from "../BaseControl/BaseControl"
import { Controller, useFormContext } from "react-hook-form"
import { PhoneInput as InternationalPhoneInput } from "react-international-phone"
import "react-international-phone/style.css"

interface IProps extends ControlProps {}

const PhoneInput = (props: IProps) => {
  const { control } = useFormContext()

  return (
    <BaseControl {...props}>
      <Controller
        control={control}
        name={props.name}
        render={({ field: { onChange, onBlur, value } }) => (
          <InternationalPhoneInput
            defaultCountry="hu"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      ></Controller>
    </BaseControl>
  )
}

export default PhoneInput
