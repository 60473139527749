import { z } from "zod"
import { baseApi } from "./api"
import { ERROR_CODES } from "../constants/textCodes"

const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<User, LoginSchema>({
      query: (body) => ({
        url: "/auth/login",
        method: "POST",
        data: body,
      }),
    }),
    register: build.mutation<User, RegisterSchema>({
      query: (body) => ({
        url: "/auth/register",
        method: "POST",
        data: body,
      }),
    }),
    refreshToken: build.mutation<void, void>({
      query: () => ({
        url: "/auth/refresh",
        method: "POST",
      }),
      invalidatesTags: (result, error) => {
        return error ? [] : [{ type: "users" }]
      },
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
    }),
  }),
  overrideExisting: true,
})

export default authApi
export const {
  useLoginMutation,
  useRefreshTokenMutation,
  useLogoutMutation,
  useRegisterMutation,
} = authApi

export const loginSchema = z.object({
  email: z.string().email(ERROR_CODES.invalidEmail),
  password: z.string().min(1, ERROR_CODES.required),
})

export type LoginSchema = z.infer<typeof loginSchema>

export const registerSchema = z
  .object({
    email: z.string().email(ERROR_CODES.invalidEmail),
    firstName: z.string().min(1, ERROR_CODES.required),
    lastName: z.string().min(1, ERROR_CODES.required),
    team: z.string().min(1, ERROR_CODES.required),
    password: z.string().min(1, ERROR_CODES.required),
    confirmPassword: z.string().min(1, ERROR_CODES.required),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: ERROR_CODES.passwordMatch,
    path: ["confirmPassword"],
  })

export type RegisterSchema = z.infer<typeof registerSchema>

export type User = {
  id: number
  email: string
  firstName: string
  lastName: string
  defaultTeam: number | null
}
