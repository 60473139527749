import { baseApi } from "./api"
import { User } from "./auth"

const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    user: build.query<User, void>({
      query: () => ({
        url: "/users/current-user",
        method: "GET",
      }),
      providesTags: (result, error) =>
        result || error ? [{ type: "users", id: result?.id }] : [],
    }),
  }),
  overrideExisting: true,
})

export default userApi
export const { useUserQuery } = userApi
