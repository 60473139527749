import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { store } from "../store/store"

export const HEADERS: Readonly<Record<string, string | boolean>> = {
  "Content-Type": "application/json; charset=UTF-8",
  Accept: "application/json",
  "Access-Control-Allow-Credentials": true,
  "X-Requested-With": "XMLHttpRequest",
}

export class ApiClient {
  private instance: AxiosInstance | null = null

  public get http(): AxiosInstance {
    return this.instance !== null ? this.instance : this.initHttp()
  }

  private initHttp() {
    const http = axios.create({
      headers: HEADERS,
      withCredentials: true,
    })

    http.interceptors.request.use(async (config) => {
      const teamId = store.getState().teamsSlice.selectedTeam

      if (config.headers["team-id"] === undefined) {
        config.headers["team-id"] = teamId
      }

      return config
    })

    this.instance = http
    return http
  }

  request<T>(config: AxiosRequestConfig) {
    return this.http.request<T>(config)
  }
}
