import BaseControl, { ControlProps } from "../BaseControl/BaseControl"

interface IProps extends ControlProps {
  type?: HTMLInputElement["type"]
}

const TextInput = ({ type = "text", ...rest }: IProps) => {
  return (
    <BaseControl {...rest}>
      <input type={type} />
    </BaseControl>
  )
}

export default TextInput
